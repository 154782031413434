import React from 'react'
import { Link } from 'gatsby'
import { Button } from 'theme-ui'
import { Layout, Main, Stack } from '@layout'
import PageTitle from '@components/PageTitle'
import Section from '@components/Section'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import splitbee from '@splitbee/web'

const Page404 = props => {
  splitbee.init({
    token: process.env.SPLITBEE_TOKEN
  })

  splitbee.track('404 Page', {
    // we can add more info here to the event, if necessary.
    //type: 'enterprise'
  })

  return (
    <Layout {...props}>
      <Seo title='Page Not Found' />
      <Divider />
      <Stack>
        <Main>
          <Section>
            <PageTitle
              header="Sorry, this page isn't available."
              subheader='You may have mistyped the address or the page may have moved.'
            />
          </Section>
          <Section>
            <Button variant='primary' as={Link} to='/'>
              Go to homepage
            </Button>
          </Section>
        </Main>
      </Stack>
    </Layout>
  )
}

export default Page404
